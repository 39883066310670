import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo-white.svg'
import './style.css'
import call from '../../assets/images/icons/call.svg'
import mail from '../../assets/images/icons/mail.svg'
import AOS from 'aos'

const data = [
    {
        id: 1,
        text: "Instagram",
        ref: "https://www.instagram.com/designerom.ux?igsh=MTRuZ2NqZ2k2cWJ4Ng==",
    },
    {
        id: 2,
        text: "Facebook",
        ref: "https://www.facebook.com/profile.php?id=61554653230905",
    },
    {
        id: 3,
        text: "Behance",
        ref: "https://www.behance.net/designom",
    },
    {
        id: 4,
        text: "Dribble",
        ref: "https://dribbble.com/designom_11",
    },
    {
        id: 5,
        text: "LinkedIn",
        ref: "https://www.linkedin.com/in/om-vaghela-84974622b/",
    },
    {
        id: 6,
        text: "Twitter",
        ref: "https://twitter.com/designom1523",
    },
    {
        id: 7,
        text: "Threads",
        ref: "https://www.threads.net/@designerom.ui",
    },
]

const Footer = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    const [experience, setExperience] = useState('');
    useEffect(() => {
        const calculateExperience = () => {
            const startYear = 2021;
            const startMonth = 11;
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth() + 1;

            let experienceYears = currentYear - startYear;

            if (currentMonth < startMonth) {
                experienceYears -= 1;
            }

            return `${experienceYears}+`;
        };
        setExperience(calculateExperience());
    }, []);
    return (
        <footer className='footer'>
            <section className="py-5 footer__top">
                <div className="container-xl" data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>
                    <Link to={"/"} ><img src={logo} alt='' className='footer__logo' /></Link>

                    <div className="d-flex flex-md-row flex-column justify-content-md-between mt-md-5 mt-4 gap-md-4 gap-5">
                        <p className='fm white footer__para mb-0'>For more than {experience} years, we have been using UX/UX Design and Graphics Design to bring brands to life.</p>
                        <div className='d-flex flex-column gap-3 justify-content-between'>
                            <a href="mailto:support@omvaghelasj1523@gmail.com" target='_blank' rel="noreferrer" className='footer__link d-flex align-items-center gap-2 fs-6 white text-decoration-none'>
                                <div className='footer__icon'><img src={mail} alt='' className='footer__icon--img' /></div> omvaghelasj1523@gmail.com
                            </a>
                            <a href="tel:+919904168345" className='footer__link d-flex align-items-center gap-2 fs-6 white text-decoration-none'>
                                <div className='footer__icon'><img src={call} alt='' className='footer__icon--img' /></div> +919904168345
                            </a>

                        </div>
                    </div>
                </div>
            </section>

            <section className='py-3' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={0}>
                <div className="container-xl d-md-flex justify-content-center">
                    <ul className='list-unstyled d-md-flex d-none mb-0 gap-md-5 gap-3 justify-content-around footer__bottom p-0'>
                        {data.map((item) => (
                            <li key={item.id} className='underline-hover footer__bottom__item'><a href={item.ref} target='_blank' rel="noreferrer" className='white text-decoration-none '>{item.text}</a></li>
                        ))}
                    </ul>
                    <div className='d-md-none d-sm-flex d-none justify-content-between'>
                        <ul className='d-flex list-unstyled flex-column gap-3'>
                            {data.slice(0, 2).map((item) => (
                                <li key={item.id} className='underline-hover footer__bottom__item'><a href={item.ref} target='_blank' rel="noreferrer" className='white text-decoration-none '>{item.text}</a></li>
                            ))}
                        </ul>
                        <ul className='d-flex list-unstyled flex-column gap-3'>
                            {data.slice(2, 4).map((item) => (
                                <li key={item.id} className='underline-hover footer__bottom__item'><a href={item.ref} target='_blank' rel="noreferrer" className='white text-decoration-none '>{item.text}</a></li>
                            ))}
                        </ul>
                        <ul className='d-flex list-unstyled flex-column gap-3'>
                            {data.slice(4, 6).map((item) => (
                                <li key={item.id} className='underline-hover footer__bottom__item'><a href={item.ref} target='_blank' rel="noreferrer" className='white text-decoration-none '>{item.text}</a></li>
                            ))}
                        </ul>
                        <ul className='d-flex list-unstyled flex-column gap-3'>
                            {data.filter(item => item.id === 7).map((item) => (
                                <li key={item.id} className='underline-hover footer__bottom__item'><a href={item.ref} target='_blank' rel="noreferrer" className='white text-decoration-none '>{item.text}</a></li>
                            ))}
                        </ul>
                    </div>
                    <div className='d-sm-none d-flex justify-content-between'>
                        <ul className='d-flex list-unstyled flex-column gap-3'>
                            {data.slice(0, 3).map((item) => (
                                <li key={item.id} className='underline-hover footer__bottom__item'><a href={item.ref} target='_blank' rel="noreferrer" className='white text-decoration-none '>{item.text}</a></li>
                            ))}
                        </ul>
                        <ul className='d-flex list-unstyled flex-column gap-3'>
                            {data.slice(4, 7).map((item) => (
                                <li key={item.id} className='underline-hover footer__bottom__item'><a href={item.ref} target='_blank' rel="noreferrer" className='white text-decoration-none '>{item.text}</a></li>
                            ))}
                        </ul>
                        <ul className='d-flex list-unstyled flex-column gap-3'>
                            {data.filter(item => item.id === 4).map((item) => (
                                <li key={item.id} className='underline-hover footer__bottom__item'><a href={item.ref} target='_blank' rel="noreferrer" className='white text-decoration-none '>{item.text}</a></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        </footer>
    )
}

export default Footer